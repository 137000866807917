<template>
  <div class="content">
    <div class="container intro">
      <div class="flex">
        <div class="main well" v-if="this.isMobile">
          <a-row :gutter="50" class="pt-10">
            <a-col :sm="24">
              <h1>Aan de slag</h1>
              <p>Deze website is niet beschikbaar op mobiel.</p>
            </a-col>
          </a-row>
        </div>
        <div class="main_loading" v-if="getSurvey().status.loading">
          <a-spin size="large"/>
        </div>
        <div class="main well" v-if="(!this.isMobile && !this.isSupportedBrowser)">
          <a-row :gutter="50" class="pt-10">
            <a-col :sm="24">
              <h1>Niet ondersteunde browser</h1>
              <p>Voor het actualiseren van je gegevens maken we gebruik van een tijdelijke extensie die niet door je huidige browser wordt ondersteund. Je kan het beste gebruik maken van Chrome</p>
            </a-col>
          </a-row>
        </div>
        <div class="main" v-if="!this.isMobile && this.isSupportedBrowser && !getSurvey().status.loading">
          <div class="main well" v-if="!hasValidInvitation">
            <a-row v-if="vehAssociated" :gutter="50">
              <a-col :sm="24">
                <h1>Geen geldige uitnodiging</h1>
                <p>Er staat momenteel geen uitnodiging open om je gegevens te delen. Denk je dat dit niet klopt, of wil je je gegevens actualiseren? Neem dan contact op met je adviseur.</p>
              </a-col>
            </a-row>
            <a-row v-else :gutter="50">
              <a-col :sm="24">
                <h1>Geen geldige uitnodiging</h1>
                <p>Er staat momenteel geen uitnodiging open om je gegevens te delen. Denk je dat dit niet klopt, of wil je je gegevens actualiseren? Neem dan contact op met je adviseur.</p>
              </a-col>
            </a-row>
            <a-button
                class="btn rounded primary"
                size="middle"
                @click="onLogout()"
                style="width: 15%;"
            >
              Uitloggen
            </a-button>
          </div>
          <div v-else>
            <a-row :gutter="50">
              <a-col :sm="24">
                <h1>{{ title }}</h1>
                <p>{{ text }}</p>
              </a-col>
            </a-row>
            <a-row :gutter="50">
              <a-col :sm="24">
                <h2>Hoe gaat u gegevens aanleveren?</h2>
                <p>{{ dataProvideText }}</p>
              </a-col>
              <a-col :sm="12" :md="12" :lg="getViews(getSurvey()).length > 1 ? 12 : 24"
                     v-for="(view,idx) in getViews(getSurvey())" :key="idx"
              >
                <div class="manual" v-if="view.text_1" :style="viewStyle(view)">
                  <div :class="getViews(getSurvey()).length > 1 ? 'desc' : ''">
                    <h3 :style="fontStyle(view)">{{ view.title }}</h3>
                    <p :style="fontStyle(view)">Klik op de onderstaande knop om te starten.</p>
                    <a-button
                        :class="view.buttonClass"
                        :loading="getSurvey().status.loading"
                        size="large"
                        type="primary"
                        @click="next(view.route)"
                    >
                      {{ view.buttonText }}
                    </a-button>
                    <p :style="fontStyle(view)">{{ view.text_1 }}</p>
                    <p :style="fontStyle(view)">{{ view.text_2 }}</p>
                    <p :style="fontStyle(view)">{{ view.text_3 }}</p>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
          <a-row class="pt-20" :gutter="50">
            <a-col :sm="12" :md="12" :lg="getViews(getSurvey()).length > 1 ? 12 : 24"
                   v-for="(view,idx) in getViews(getSurvey())" :key="idx"
            >
              <div v-if="!view.text_1" :class="getViews(getSurvey()).length > 1 ? 'cta' : ''">
                <a-button
                    :class="view.buttonClass"
                    :loading="getSurvey().status.loading"
                    size="large"
                    type="primary"
                    @click="next(view.route)"
                >
                  {{ view.buttonText }}
                </a-button>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="sidebar" v-if="hasValidInvitation">
          <side-nav step="intro" :loading="getSurvey().status.loading"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDataProvideText,
  getIntro,
  getInvitationName,
  getInvitationText,
  isEigenhuis,
  isMobile,
  isSupportedBrowser,
  LOGOUT,
  router
} from '../../../helpers'
import { mapActions, mapState } from 'vuex'
import SideNav from '../../../components/SideNav/SideNav'

export default {
  name: 'IntroComponent',
  components: { SideNav },
  computed: {
    hasValidInvitation () {
      return this.$store.state.survey.customer.has_valid_invitation
    },
    ...mapState('auth', ['invitation_token']),
    ...mapState('survey', ['isHypotheekwacht'])
  },
  data () {
    const invType = this.$store.state.survey.customer.invitation.type.value
    return {
      invitationType: invType,
      title: getInvitationName(invType),
      text: getInvitationText(invType),
      dataProvideText: getDataProvideText(invType),
      isSupportedBrowser: isSupportedBrowser(),
      isMobile: isMobile(),
      vehAssociated: isEigenhuis()
    }
  },
  watch: {
    '$store.state.survey.customer.invitation.type.value': function () {
      const invType = this.$store.state.survey.customer.invitation.type.value
      this.invitationType = invType
      this.title = getInvitationName(invType)
      this.text = getInvitationText(invType)
      this.dataProvideText = getDataProvideText(invType)
    }
  },
  mounted: function () {
    this.getTenantSetting('iwize_integration')
    this.getCustomer(this.invitation_token)
    this.getHDNCompanies()
    this.getHDNCompanyLabels()
  },
  methods: {
    ...mapActions('survey', ['getCustomer', 'getHDNCompanies', 'getHDNCompanyLabels']),
    ...mapActions('auth', ['logout', 'getTenantSetting']),
    onLoad (e) {
      this.getTenantSetting('iwize_integration')
      this.getCustomer()
      this.getHDNCompanies()
      this.getHDNCompanyLabels()
    },
    getViews (data) {
      return getIntro(data.style, data.customer.has_partner.value, data.customer.invitation)
    },
    getSurvey () {
      return this.$store.state.survey
    },
    next (route) {
      if (route === LOGOUT) {
        this.logout({ redirect: '/login' })
      } else {
        router.push(route)
      }
    },
    viewStyle (view) {
      return {
        color: view.color,
        backgroundColor: view.backgroundColor
      }
    },
    fontStyle (view) {
      return {
        color: view.color
      }
    },
    onLogout (e) {
      this.logout({ redirect: '/login' })
    }
  }
}
</script>
